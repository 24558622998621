import React, { useCallback, useEffect } from "react";
import cn from "classnames";
import styles from "./RndInfo.module.sass";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
import {
  debounce,
  filter,
  isEmpty,
  keys,
  map,
  set,
  uniq,
  without,
} from "lodash";
import {
  Grid,
  Image,
  List,
  ThemeIcon,
  rem,
  Skeleton,
  Group,
  Radio,
  Flex,
} from "@mantine/core";
import { IconCircleCheck } from "@tabler/icons-react";
import {
  BRIEF_TYPE_NEW_SAMPLE_VIDEO,
  BRIEF_TYPE_ON_PLAN_VIDEO,
  BRIEF_TYPE_SAMPLE_VIDEO,
  BRIEF_TYPES,
  BRIEF_VALUES,
  CHOOSE_BRIEF_TYPES,
  PROJECTS,
  UNIQUE_TEAMS,
} from "../../../constant";
import { Autocomplete } from "@mantine/core";
import Icon from "../../../components/Icon";

const RndInfo = ({
  className,
  workGroup,
  setWorkGroup,
  setVisibleReviewTable,
  previewData,
  rndMember,
  setRndMember,
  briefType,
  setBriefType,
  search,
  setSearch,
  handleSearchSKU,
  loadingSearchSKU,
  SKU,
  products,
  designerMember,
  setDesignerMember,
  briefValue,
  setBriefValue,
  users,
  fetchAllProducts,
  briefTypes = [],
  project,
  setProject,
  directions,
  direction,
  setDirection,
}) => {
  useEffect(() => {
    if (!isEmpty(previewData)) setVisibleReviewTable(true);
  }, [previewData]);
  // Debounce the fetchAllProducts function
  const debouncedFetchAllProducts = useCallback(
    debounce((value) => {
      fetchAllProducts(value);
    }, 300),
    []
  );
  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Info của Batch"
        classTitle={cn("title-red", styles.classInfoTitle)}
        classCardHead={styles.classCardHeadInfoBatch}
        classSpanTitle={styles.classSpanTitle}
        head={
          <Flex>
            <Radio.Group
              label="Project"
              withAsterisk
              onChange={(value) => setProject(value)}
              value={project}
            >
              <Group
                justify="flex-start"
                styles={{
                  root: {
                    width: "300px",
                    marginTop: "24px",
                  },
                }}
              >
                {map(keys(PROJECTS), (key) => (
                  <Radio
                    value={PROJECTS[key].VALUE}
                    label={PROJECTS[key].LABEL}
                  />
                ))}
              </Group>
            </Radio.Group>
            <Dropdown
              label={"Direction"}
              className={styles.dropdownDirection}
              classDropdownHead={styles.dropdownHead}
              value={direction}
              setValue={setDirection}
              options={map(directions, "name")}
            />
          </Flex>
        }
      >
        <div className={styles.description}>
          <div className={styles.campType}>
            <Dropdown
              className={styles.dropdown}
              label={"RND"}
              classDropdownHead={styles.dropdownHead}
              value={rndMember}
              setValue={setRndMember}
              options={
                !isEmpty(filter(users, { position: "rnd" }))
                  ? map(filter(users, { position: "rnd" }), "name")
                  : []
              }
              classOutSideClick={styles.memberDropdown}
            />{" "}
            <Dropdown
              label={"Team"}
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              value={workGroup}
              setValue={setWorkGroup}
              options={UNIQUE_TEAMS}
              classOutSideClick={styles.memberDropdown}
            />{" "}
            <Dropdown
              label={"Value"}
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              value={briefValue}
              setValue={setBriefValue}
              options={BRIEF_VALUES}
              classOutSideClick={styles.memberDropdown}
            />{" "}
            <Dropdown
              className={styles.dropdown}
              label={"Designer"}
              classDropdownHead={styles.dropdownHead}
              value={designerMember}
              setValue={setDesignerMember}
              options={
                !isEmpty(
                  filter(users, { position: "designer", team: workGroup })
                )
                  ? map(
                      filter(users, { position: "designer", team: workGroup }),
                      "name"
                    )
                  : []
              }
              classOutSideClick={styles.memberDropdown}
            />{" "}
          </div>
        </div>
      </Card>
      <Card
        className={cn(styles.cardBriefType, className)}
        title="1. Loại Brief"
        classTitle="title-green"
        classCardHead={styles.classCardHead}
        classSpanTitle={styles.classSpanBriefTitle}
      >
        <div
          className={styles.group}
          style={{ width: "100%", marginBottom: 24 }}
        >
          <Dropdown
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            value={briefType}
            setValue={setBriefType}
            options={
              briefTypes ||
              without(
                CHOOSE_BRIEF_TYPES,
                BRIEF_TYPE_SAMPLE_VIDEO,
                BRIEF_TYPE_NEW_SAMPLE_VIDEO,
                BRIEF_TYPE_ON_PLAN_VIDEO
              )
            }
          />{" "}
        </div>
      </Card>
      {briefType !== BRIEF_TYPES[3] &&
        briefType !== BRIEF_TYPES[4] &&
        briefType !== BRIEF_TYPES[5] &&
        briefType !== BRIEF_TYPES[9] &&
        briefType !== BRIEF_TYPES[11] && (
          <Card
            className={cn(styles.cardBriefType, className)}
            title="2. Input Ref"
            classTitle="title-green"
            classCardHead={styles.classCardHead}
            classSpanTitle={styles.classSpanBriefTitle}
            head={
              <Autocomplete
                placeholder="Search SKU"
                data={uniq(map(products, "SKU"))}
                withScrollArea={true}
                defaultDropdownOpened={false}
                maxDropdownHeight={100}
                leftSection={
                  <span
                    onClick={handleSearchSKU}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <Icon name="search" size={18} />
                  </span>
                }
                value={search}
                onChange={(value) => {
                  setSearch(value);
                  debouncedFetchAllProducts(value);
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    handleSearchSKU();
                  }
                }}
              />
            }
          >
            {SKU && !loadingSearchSKU && (
              <Grid>
                <Grid.Col span={4}>
                  <Image
                    radius="md"
                    src={SKU.image || "/images/content/not_found_2.jpg"}
                  />
                </Grid.Col>
                <Grid.Col span={8}>
                  <List
                    spacing="lg"
                    size="sm"
                    center
                    icon={
                      <ThemeIcon color="teal" size={24} radius="xl">
                        <IconCircleCheck
                          style={{ width: rem(16), height: rem(16) }}
                        />
                      </ThemeIcon>
                    }
                  >
                    {SKU.productLink && (
                      <List.Item>
                        Link Product:{" "}
                        <a
                          style={{
                            display: "inline-block",
                            width: "200px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            textDecoration: "none",
                            color: "#228be6",
                            verticalAlign: "middle",
                          }}
                          href={SKU.productLink}
                          target="_blank"
                        >
                          {SKU.productLink}
                        </a>
                      </List.Item>
                    )}
                    {SKU.designLink && (
                      <List.Item>
                        Link Design:{" "}
                        <a
                          style={{
                            display: "inline-block",
                            width: "200px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            textDecoration: "none",
                            color: "#228be6",
                            verticalAlign: "middle",
                          }}
                          href={SKU.designLink}
                          target="_blank"
                        >
                          {SKU.designLink}
                        </a>
                      </List.Item>
                    )}
                    {SKU.mockupLink && (
                      <List.Item>
                        Link Mockup:{" "}
                        <a
                          style={{
                            display: "inline-block",
                            width: "200px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            textDecoration: "none",
                            color: "#228be6",
                            verticalAlign: "middle",
                          }}
                          href={SKU.mockupLink}
                          target="_blank"
                        >
                          {SKU.mockupLink}
                        </a>
                      </List.Item>
                    )}
                    {SKU.tibLink && (
                      <List.Item>
                        Link TIB:{" "}
                        <a href={SKU.tibLink} target="_blank">
                          Click
                        </a>
                      </List.Item>
                    )}
                    {SKU.productLine && (
                      <List.Item>PL: {SKU.productLine}</List.Item>
                    )}
                  </List>
                  <hr />
                  {SKU?.isDifferentProductLine && (
                    <i>
                      Lưu ý: Không tìm thấy PL trên Library
                      {SKU?.productLineDetectedBy
                        ? ` nhưng detect được theo Product Type: ${
                            SKU?.productType
                          } ${
                            SKU?.skuPrefix
                              ? `và SKU Prefix: ${SKU?.skuPrefix}`
                              : ""
                          }`
                        : ""}
                    </i>
                  )}
                </Grid.Col>
              </Grid>
            )}
            {loadingSearchSKU && (
              <Grid>
                <Grid.Col span={6}>
                  <Skeleton height={260} radius="md" />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Skeleton height={50} mt={10} radius="md" />
                  <Skeleton height={50} mt={10} radius="md" />
                  <Skeleton height={50} mt={10} radius="md" />
                  <Skeleton height={50} mt={10} radius="md" />
                </Grid.Col>
              </Grid>
            )}
          </Card>
        )}
    </>
  );
};

export default RndInfo;
