import { useState } from "react";
import { campaignServices } from "../../../services";
import { CONVERT_STATUS_TO_NUMBER } from "../../../utils";
import formatDate from "../../../utils/formatDate";
import { showNotification } from "../../../utils/index";
import useGetUser from "../../../hooks/useGetUser";
import useGetSupplierAttribute from "../../../hooks/useGetSupplierAttribute";
import useGetSampleList from "../../../hooks/useGetSampleList";
import useCreateRequest from "../ModalRequestOldSKU/useCreateRequest";
import { STATUS } from "../../../constant";

const defaultFilters = {
  videoStatus: [1],
  antiPriority: true,
};

const useTable = ({ query, setQuery, sorting, setSorting }) => {
  const { users } = useGetUser();
  const { supplierAttributes } = useGetSupplierAttribute();
  const { data, pagination, handleChangePage, refetch } = useGetSampleList({
    filters: query,
    defaultFilters,
    endPoint: "/briefs/video-sample/v2",
    sorting,
    setSorting,
  });
  const { createRequest, loading, error } = useCreateRequest({
    query,
  });
  const [searchSKU, setSearchSKU] = useState("");

  const handleChangeSKU = (e) => {
    setSearchSKU(e.target.value);
  };

  const handleCreateRequest = async (data) => {
    await createRequest(data);
    setQuery({
      status: [1],
      statusValue: "Undone",
    });
  };

  const handleSubmitSKU = () => {
    setQuery({
      ...query,
      sku: searchSKU,
    });
    handleChangePage();
  };

  const handleChangeDate = (dateValue, startDate, endDate) => {
    if (!startDate) {
      setQuery({
        ...query,
        startVideoRequestedDate: null,
        endVideoRequestedDate: null,
        dateValue: null,
      });
      return;
    }

    setQuery({
      ...query,
      dateValue,
      startVideoRequestedDate: formatDate(startDate),
      endVideoRequestedDate: formatDate(endDate),
    });
    handleChangePage();
  };

  const clearFilters = () => {
    setQuery({
      sku: null,
      dateValue: null,
      startVideoRequestedDate: null,
      endVideoRequestedDate: null,
      value: null,
      valueName: null,
      rndTeam: null,
      rndName: null,
      rndId: null,
      status: [1],
      projects: null,
      statusValue: "Undone",
      hasSupplierValue: null,
      videoSupplierValue: null,
      videoSupplier: null,
      isFileSup: null,
      antiPriority: true,
      archive: null,
      isSendRequestSup: null,
      isSendRequestSupValue: null,
      picSupplier: null,
      picSupplierValue: null,
    });
    setSearchSKU("");
  };

  const handleChangeSizeValue = (value) => {
    setQuery({
      ...query,
      value: CONVERT_STATUS_TO_NUMBER[value],
      valueName: value,
    });
    handleChangePage();
  };

  const handleClearSizeValue = () => {
    setQuery({
      ...query,
      value: null,
      valueName: null,
    });
    handleChangePage();
  };

  const handleChangeTeam = (value) => {
    setQuery({ ...query, rndTeam: value });
    handleChangePage();
  };

  const handleClearTeam = () => {
    setQuery({
      ...query,
      rndTeam: null,
    });
    handleChangePage();
  };

  const handleChangeStatus = (value) => {
    let status;
    let archive = null;
    switch (value) {
      case "Done":
        status = [2];
        break;
      case "Undone":
        status = [1];
        break;
      case "Archived":
        archive = STATUS.ARCHIVED;
        break;
      default:
        status = [1, 2];
        archive = null;
        break;
    }
    setQuery({
      ...query,
      status,
      statusValue: value,
      ...(archive
        ? { archive, status: [1, 2] }
        : {
            archive: null,
            status,
          }),
    });
    handleChangePage();
  };

  const handleClearStatus = () => {
    setQuery({
      ...query,
      status: [1, 2],
      statusValue: null,
      archive: null,
    });
    handleChangePage();
  };
  const handleClearSupplier = () => {
    setQuery({
      ...query,
      videoSupplier: null,
      videoSupplierValue: null,
      picSupplier: null,
      picSupplierValue: null,
    });
    handleChangePage();
  };

  const handleChangeHasSupplier = (value) => {
    setQuery({
      ...query,
      hasSupplierValue: value,
      ...(value && {
        hasSupplier: true,
      }),
    });
    handleChangePage();
  };

  const handleChangeSupplier = (value) => {
    setQuery({
      ...query,
      picSupplier: value === "NA" ? "" : value,
      picSupplierValue: value,
      // videoSupplierValue: value,
    });
    handleChangePage();
  };

  const handleChangeProject = (value) => {
    setQuery({
      ...query,
      projects: [value],
    });
    handleChangePage();
  };

  const handleClearProject = () => {
    setQuery({
      ...query,
      projects: null,
    });
    handleChangePage();
  };

  const handleChangeFileSup = (value) => {
    const realValue = value === "All" ? null : value === "Done" ? true : false;
    setQuery({
      ...query,
      isSendRequestSup: realValue,
      isSendRequestSupValue: value,
    });
    handleChangePage();
  };
  const handleClearFileSup = () => {
    setQuery({
      ...query,
      isSendRequestSup: null,
      isSendRequestSupValue: null,
    });
    handleChangePage();
  };

  const handleUpdateSampleAttribute = async (briefId, sampleAttribute) => {
    const result = await campaignServices.updateVideoSampleBrief(briefId, {
      sampleAttribute,
    });

    if (result.success) {
      showNotification(
        "Thành công",
        "Update Supplier Attribute thành công",
        "green"
      );
      refetch();
    }
  };

  const handleUpdateSupplier = async (briefId, picSupplier) => {
    const result = await campaignServices.updateVideoSampleBrief(briefId, {
      picSupplier,
    });
    if (result.success) {
      showNotification("Thành công", "Update Supplier thành công", "green");
      refetch();
    }
  };
  const handleUpdatePIC = async (briefId, videoSupplier) => {
    const result = await campaignServices.updateVideoSampleBrief(briefId, {
      videoSupplier,
    });
    if (result.success) {
      showNotification("Thành công", "Update PIC thành công", "green");
      refetch();
    }
  };
  const handleUpdateTeam = async (briefId, rndTeam) => {
    const result = await campaignServices.updateVideoSampleBrief(briefId, {
      rndTeam,
    });
    if (result.success) {
      showNotification("Thành công", "Update thành công", "green");
      refetch();
    }
  };

  const handleDoneSample = async (briefId) => {
    const result = await campaignServices.updateVideoSampleBrief(briefId, {
      videoStatus: 2,
    });
    if (result.success) {
      showNotification("Thành công", "Update thành công", "green");
      refetch();
    }
  };

  const handleIncompleteSample = async (briefId) => {
    const result = await campaignServices.updateVideoSampleBrief(briefId, {
      videoStatus: 1,
    });
    if (result.success) {
      showNotification("Thành công", "Update thành công", "green");
      refetch();
    }
  };
  const handleUpdateNote = async (briefId, note) => {
    const result = await campaignServices.updateVideoSampleBrief(briefId, note);
    if (result.success) {
      showNotification("Thành công", "Update Note thành công", "green");
      refetch();
    }
  };

  const handleUpdateLinkFileIn = async (briefId, videoFileIn) => {
    const result = await campaignServices.updateVideoSampleBrief(briefId, {
      videoFileIn,
    });
    if (result.success) {
      showNotification("Thành công", "Update thành công", "green");
      refetch();
    }
  };
  const handleUpdateValue = async (briefId, value) => {
    const result = await campaignServices.updateVideoSampleBrief(briefId, {
      value,
    });
    if (result.success) {
      showNotification("Thành công", "Update value thành công", "green");
      refetch();
    }
  };

  const handleRemoveSample = async (briefId) => {
    const result = await campaignServices.removeVideoSampleBrief(briefId);
    if (result.success) {
      showNotification("Thành công", "Xóa thành công", "green");
      refetch();
    }
  };

  const handleUpdatePrintUrl = async (briefId, sampleFileUrl) => {
    const result = await campaignServices.updateVideoSampleBrief(briefId, {
      sampleFileUrl,
    });
    if (result.success) {
      showNotification("Thành công", "Update thành công", "green");
      refetch();
    }
  };

  const handleUpdateFileSup = async (briefId, isSendRequestSup) => {
    const result = await campaignServices.updateVideoSampleBrief(briefId, {
      isSendRequestSup,
    });
    if (result.success) {
      showNotification("Thành công", "Update thành công", "green");
      refetch();
    }
  };

  const handleArchiveBrief = async (briefId, archive) => {
    const result = await campaignServices.updateVideoSampleBrief(briefId, {
      archive,
    });
    if (result.success) {
      showNotification("Thành công", "Update thành công", "green");
      refetch();
    }
  };

  return {
    data,
    users,
    searchSKU,
    supplierAttributes,
    pagination,
    clearFilters,
    handleChangeSKU,
    handleSubmitSKU,
    handleChangeDate,
    handleClearSizeValue,
    handleChangeSizeValue,
    handleChangeTeam,
    handleClearTeam,
    handleChangeStatus,
    handleClearStatus,
    handleChangePage,
    handleUpdateSupplier,
    handleUpdateSampleAttribute,
    handleDoneSample,
    handleIncompleteSample,
    handleUpdateLinkFileIn,
    handleUpdateValue,
    refetch,
    createRequest,
    loading,
    error,
    handleCreateRequest,
    handleRemoveSample,
    handleUpdateTeam,
    handleChangeHasSupplier,
    handleChangeSupplier,
    handleClearSupplier,
    handleUpdateNote,
    handleUpdateFileSup,
    handleChangeFileSup,
    handleClearFileSup,
    handleArchiveBrief,
    handleClearProject,
    handleChangeProject,
    handleUpdatePIC,
    handleUpdatePrintUrl,
  };
};

export default useTable;
